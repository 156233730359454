import {
    USERS_FETCH_SUCCESS,
    USERS_FETCH_FAILURE,
    USER_FETCH_SUCCESS,
    USER_FETCH_FAILURE,
    USER_EDIT,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILED,
    USER_UPDATE_END,
    USER_NOTIFICATION_SUCCESS,
    USER_NOTIFICATION_FAILURE,
    USER_NOTIFICATION_END,
    USER_TERMINATE_SUCCESS,
    USER_TERMINATE_FAILURE,
    USER_TERMINATE_END,
    USER_REVIEW_FETCH_SUCCESS,
    USER_REVIEW_FETCH_FAILURE,
    USER_IDENTITY_SUCCESS,
    USER_IDENTITY_FAILURE,
    USER_CREDIT_SUCCESS,
    USER_CREDIT_FAILURE,
    USER_CREDIT_CHECK_SUCCESS,
    USER_IDENTITY_CHECK_SUCCESS,
    USER_IDENTITY_CHECK_FAILURE,
    USER_CREDIT_CHECK_FAILURE, WORKERS_FETCH_SUCCESS, WORKERS_FETCH_FAILURE,
} from "../constants/actionType";

const initialState = {
    list: [],
    workersList: [],
    item: null,
    reviews: [],
    fetchStatus: '',
    updateStatus: '',
    notificationStatus: '',
    terminatedStatus: ''
}

const userReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        case USERS_FETCH_SUCCESS:
            return Object.assign({}, state, {
                list: action.data
            })

        case USERS_FETCH_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case WORKERS_FETCH_SUCCESS:
            return Object.assign({}, state, {
                workersList: action.data
            })

        case WORKERS_FETCH_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case USER_FETCH_SUCCESS:
            return Object.assign({}, state, {
                item: action.data,
                fetchStatus: 'success'
            })

        case USER_FETCH_FAILURE:
            return Object.assign({}, state, {
                fetchStatus: 'failed'
            })

        case USER_EDIT:
            return Object.assign({}, state, {
                item: {...state.item, ...action.data}
            })

        case USER_UPDATE_SUCCESS:
            return Object.assign({}, state, {
                item: action.data,
                updateStatus: 'success'
            })

        case USER_UPDATE_FAILED:
            return Object.assign({}, state, {
                updateStatus: 'failed'
            })

        case USER_UPDATE_END:
            return Object.assign({}, state, {
                updateStatus: ''
            })

        case USER_NOTIFICATION_SUCCESS:
            return Object.assign({}, state, {
                item: action.data,
                notificationStatus: action.data.notification
            })

        case USER_NOTIFICATION_FAILURE:
            return Object.assign({}, state, {
                notificationStatus: 'failed'
            })

        case USER_NOTIFICATION_END:
            return Object.assign({}, state, {
                notificationStatus: ''
            })

        case USER_TERMINATE_SUCCESS:
            return Object.assign({}, state, {
                terminatedStatus: 'success',
                item: action.data
            })

        case USER_TERMINATE_FAILURE:
            return Object.assign({}, state, {
                terminatedStatus: 'failed'
            })

        case USER_TERMINATE_END:
            return Object.assign({}, state, {
                terminatedStatus: ''
            })

        case USER_REVIEW_FETCH_SUCCESS:
            return Object.assign({}, state, {
                reviews: action.data
            })

        case USER_REVIEW_FETCH_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case USER_IDENTITY_SUCCESS:
            return Object.assign({}, state, {
                item: action.data
            })

        case USER_IDENTITY_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case USER_CREDIT_SUCCESS:
            return Object.assign({}, state, {
                item: action.data
            })

        case USER_CREDIT_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case USER_IDENTITY_CHECK_SUCCESS:
            return Object.assign({}, state, {
                item: action.data
            })

        case USER_IDENTITY_CHECK_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case USER_CREDIT_CHECK_SUCCESS:
            return Object.assign({}, state, {
                item: action.data
            })

        case USER_CREDIT_CHECK_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        default:
            return state
    }
}

export default userReducer;