import {
    CITY_SUCCESS,
    CITY_FAILURE, CITY_ADD_SUCCESS, CITY_ADD_FAILURE
} from "../constants/actionType"

let initialState = {
    data: [],
}

const cityReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case CITY_SUCCESS:
            return Object.assign({}, state, {
                data: action.data
            })

        case CITY_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case CITY_ADD_SUCCESS:
            return Object.assign({}, state, {
                data: action.data
            })

        case CITY_ADD_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        default:
            return state
    }
}

export default cityReducer

