import {
    JOBS_FETCH_SUCCESS,
    JOBS_FETCH_FAILURE,
    JOB_FETCH_SUCCESS,
    JOB_FETCH_FAILURE,
    JOB_POST_SUCCESS,
    JOB_POST_FAILURE,
    JOB_POST_END,
    JOB_EDIT,
    JOB_UPDATE_SUCCESS,
    JOB_UPDATE_FAILURE,
    JOB_FINISH_SUCCESS,
    JOB_FINISH_FAILURE,
    JOB_QUOTE_EDIT,
    JOB_QUOTE_SUCCESS,
    JOB_QUOTE_FAILURE,
    JOB_QUOTE_UPDATE_SUCCESS,
    JOB_QUOTE_UPDATE_FAILURE,
    JOB_QUOTES_FETCH_SUCCESS,
    JOB_QUOTES_FETCH_FAILURE,
    JOB_ACCEPT_SUCCESS,
    JOB_ACCEPT_FAILURE,
    JOB_DECLINE_SUCCESS,
    JOB_DECLINE_FAILURE,
    JOBS_COMPLETED_FETCH_FAILURE, JOBS_COMPLETED_FETCH_SUCCESS, JOBS_ONGOING_FETCH_SUCCESS, JOBS_ONGOING_FETCH_FAILURE
} from "../constants/actionType";

let initialState = {
    list: [],
    completedList: [],
    ongoingList: [],
    item: null,
    quote: null,
    quotes: [],
    postSuccess: null,
    updateSuccess: null,
    quoteSuccess: null,
    quoteUpdateSuccess: null,
    finishSuccess: null
}

const jobReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case JOBS_FETCH_SUCCESS:
            return Object.assign({}, state, {
                list: action.data
            })

        case JOBS_FETCH_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case JOBS_COMPLETED_FETCH_SUCCESS:
            return Object.assign({}, state, {
                completedList: action.data
            })

        case JOBS_COMPLETED_FETCH_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case JOBS_ONGOING_FETCH_SUCCESS:
            return Object.assign({}, state, {
                ongoingList: action.data
            })

        case JOBS_ONGOING_FETCH_FAILURE:
            return Object.assign({}, {
                errorMessage: action.error.message
            })

        case JOB_FETCH_SUCCESS:
            return Object.assign({}, state, {
                item: action.data
            })

        case JOB_FETCH_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case JOB_POST_SUCCESS:
            return Object.assign({}, state, {
                postSuccess: true
            })

        case JOB_POST_FAILURE:
            return Object.assign({}, state, {
                postSuccess: false,
                errorMessage: action.error.message
            })

        case JOB_POST_END:
            return Object.assign({}, state, {
                postSuccess: null
            })

        case JOB_EDIT:
            return Object.assign({}, state, {
                item: {...state.item, ...action.data}
            })

        case JOB_UPDATE_SUCCESS:
            return Object.assign({}, state, {
                updateSuccess: true
            })

        case JOB_UPDATE_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case JOB_QUOTES_FETCH_SUCCESS:
            return Object.assign({}, state, {
                quotes: action.data
            })

        case JOB_QUOTES_FETCH_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case JOB_QUOTE_EDIT:
            return Object.assign({}, state, {
                quote: {...state.quote, ...action.data}
            })

        case JOB_QUOTE_SUCCESS: {
            return Object.assign({}, state, {
                item: action.data,
                quoteSuccess: true
            })
        }

        case JOB_QUOTE_FAILURE: {
            return Object.assign({}, state, {
                quoteSuccess: false,
                errorMessage: action.error.message
            })
        }

        case JOB_QUOTE_UPDATE_SUCCESS: {
            return Object.assign({}, state, {
                item: action.data,
                quoteUpdateSuccess: true
            })
        }

        case JOB_QUOTE_UPDATE_FAILURE: {
            return Object.assign({}, state, {
                quoteUpdateSuccess: false
            })
        }

        case JOB_ACCEPT_SUCCESS:
            return Object.assign({}, state, {
                item: action.data
            })

        case JOB_ACCEPT_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case JOB_DECLINE_SUCCESS:
            return Object.assign({}, state, {
                item: action.data
            })

        case JOB_DECLINE_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case JOB_FINISH_SUCCESS:
            return Object.assign({}, state, {
                finishSuccess: true
            })

        case JOB_FINISH_FAILURE:
            return Object.assign({}, state, {
                finishSuccess: false,
                errorMessage: action.error.message
            })

        default:
            return state
    }
}

export default jobReducer