import jwtDecode from "jwt-decode";
import {getLocalStorage} from "./stroageUtil";
import {USER} from "../config/config";

export let isTokenExpired = (user) => {
    const token = user.token
    try {
        const decoded = jwtDecode(token);
        if (decoded.exp < Date.now() / 1000) {
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
};

export let getUser = () => {
    return getLocalStorage(USER);
};

export let isAuthenticated = () => {
    return !!getUser() && !isTokenExpired(getUser());
};

export let getUserType = () => {
    const user = getUser()
    if (user.user) {
        if (user.user.type === 1) {
            return 'user'
        } else if (user.user.type === 2) {
            return 'worker';
        } else if (user.user.type === 0) {
            return 'admin';
        }
    }
}

export let getToken = () => {
    const user = getUser()
    if (user && user.token) {
        return user.token
    } else {
        return null
    }
}

// const refreshTokenSetup = (res) => {
//     let refreshTiming = (res.tokenObj.expires_in || 3600 - 5* 60) * 1000;
//
//     const refreshToken = async () => {
//         const newAuthRes = await res.reloadAuthResponse();
//         refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
//
//         setTimeout(refreshToken, refreshTiming);
//     }
//
//     setTimeout(refreshToken, refreshTiming);
// }