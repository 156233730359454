export const USER = 'user';

export const apiPath = 'api/';

// export const APP_HOST = process.env.APP_HOST || 'localhost';
export const APP_HOST = process.env.APP_HOST || '3.128.27.86';
export const APP_PORT = process.env.APP_PORT || '4300';
export const HOST = `${APP_HOST}:${APP_PORT}/`;

export const API_URL = `https://${HOST}${apiPath}`;
export const googleClientId = '1089703728163-1912q4lirc8trnhnah2500qub9ea6t9j.apps.googleusercontent.com';
