import {
    BUSINESS_SUCCESS,
    BUSINESS_FAILURE, BUSINESS_ADD_SUCCESS, BUSINESS_ADD_FAILURE
} from "../constants/actionType";

const initialState = {
    data: [],
    errorMessage: ''
}

const businessReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case BUSINESS_SUCCESS:
            return Object.assign({}, state, {
                data: action.data
            })

        case BUSINESS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case BUSINESS_ADD_SUCCESS:
            return Object.assign({}, state, {
                data: action.data
            })

        case BUSINESS_ADD_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        default:
            return state;
    }
}

export default businessReducer;