import {
    CATEGORY_SUCCESS,
    CATEGORY_FAILURE, CATEGORY_ADD_SUCCESS, CATEGORY_ADD_FAILURE, CATEGORY_TOP_SUCCESS, CATEGORY_TOP_FAILURE
} from "../constants/actionType";

let initialState = {
    data: []
}

const categoryReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case CATEGORY_SUCCESS:
            return Object.assign({}, state, {
                data: action.data
            })

        case CATEGORY_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case CATEGORY_TOP_SUCCESS:
            return Object.assign({}, state, {
                data: action.data
            })

        case CATEGORY_TOP_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        case CATEGORY_ADD_SUCCESS:
            return Object.assign({}, state, {
                data: action.data
            })

        case CATEGORY_ADD_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.error.message
            })

        default:
            return state
    }
}

export default categoryReducer