import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import loadable from '@loadable/component';

import RestrictRoute from "./RestricRoute";
import PublicRoute from "./PublicRoute";
import NotFound from "../containers/errors/NotFound";
import PrivateRoute from "./PrivateRoute";

// basic containers
const AsyncLogInForm = loadable(() => import('../containers/auth/LoginContainer'));
const AsyncSignUpForm = loadable(() => import('../containers/auth/SingupContainer'));
const AsyncMainForm = loadable(() => import('../containers/dashboard/MainContainer'));
const AsyncContactForm = loadable(() => import('../containers/contact/ContactContainer'));
const AsyncBlogsForm = loadable(() => import('../containers/blogs/BlogsContainer'));
const AsyncBlogForm = loadable(() => import('../containers/blogs/BlogContainer'));
const AsyncHowitworksForm = loadable(() => import('../containers/howitworks/HowitworksContainer'));
// worker containers
const AsyncBecomeWorkerForm = loadable(() => import('../containers/becomeworker/BecomeWorkerContainer'));
const AsyncBecomeWorkerSignupForm = loadable(() => import('../components/becomeworker/SignupStep'));
const AsyncBecomeWorkerNameForm = loadable(() => import('../components/becomeworker/NameStep'));
const AsyncBecomeWorkerAddressForm = loadable(() => import('../components/becomeworker/AddressStep'));
const AsyncBecomeWorkerPortfolioForm = loadable(() => import('../components/becomeworker/PortfolioStep'));
const AsyncBecomeWorkerCategoryForm = loadable(() => import('../components/becomeworker/CategoriesStep'));
const AsyncWorkerSettingForm = loadable(() => import('../containers/worker/ProfileSettingContainer'));
const AsyncWorkerJobListForm = loadable(() => import('../containers/worker/PublishedJobsContainer'));
const AsyncWorkerMyJobsForm = loadable(() => import('../containers/worker/OngoingJobsContainer'));
const AsyncWorkerUpdateProfileForm = loadable(() => import('../containers/worker/UpdateProfileContainer'));
const AsyncWorkerJobDetailForm = loadable(() => import('../containers/worker/JobDetailContainer'));
// user containers
const AsyncUserSettingForm = loadable(() => import('../containers/user/SettingContainer'));
const AsyncUserJobListForm = loadable(() => import('../containers/user/JobsListContainer'));
const AsyncJobDetailForm = loadable(() => import('../containers/user/JobDetailContainer'));
const AsyncUserPostJobForm = loadable(() => import('../containers/user/PostJobContainer'));
const AsyncOnGoingWorkForm = loadable(() => import('../containers/user/OngoingWorkContainer'));
// admin containers
const AsyncAdminDashboardForm = loadable(() => import('../containers/admin/DashboardContainer'));
const AsyncAdminTodaysRequestForm = loadable(() => import('../containers/admin/TodaysRequestContainer'));
const AsyncAdminUsersForm = loadable(() => import('../containers/admin/UsersContainer'));
const AsyncAdminWorkersForm = loadable(() => import('../containers/admin/WorkersContainer'));
const AsyncAdminCategoriesForm = loadable(() => import('../containers/admin/CategoriesContainer'));
const AsyncAdminBusinessTypeForm = loadable(() => import('../containers/admin/BusinessTypeContainer'));
const AsyncAdminCitiesForm = loadable(() => import('../containers/admin/CitiesContainer'));
const AsyncAdminBlogForm = loadable(() => import('../containers/admin/BlogContainer'));
const AsyncAdminSettingForm = loadable(() => import('../containers/admin/SettingContainer'));
// unused containers
const AsyncAdminUsersDetailForm = loadable(() => import('../containers/admin/UserDetailContainer'));

const Router = () => (
    <Fragment>
        <Switch>
            <RestrictRoute exact path="/" layout={AsyncMainForm} />
            <RestrictRoute exact path="/login" layout={AsyncLogInForm} />
            <RestrictRoute exact path="/register" layout={AsyncSignUpForm} />

            <PublicRoute exact path="/blogs" component={AsyncBlogsForm} />
            <PublicRoute exact path="/blog" component={AsyncBlogForm} />
            <PublicRoute exact path="/contact" component={AsyncContactForm} />
            <PublicRoute exact path="/howitworks" component={AsyncHowitworksForm} />

            <RestrictRoute exact path="/become-worker" layout={AsyncBecomeWorkerForm} component={AsyncBecomeWorkerSignupForm} />
            <PrivateRoute exact path="/become-worker/name" layout={AsyncBecomeWorkerForm} component={AsyncBecomeWorkerNameForm} />
            <PrivateRoute exact path="/become-worker/address" layout={AsyncBecomeWorkerForm} component={AsyncBecomeWorkerAddressForm} />
            <PrivateRoute exact path="/become-worker/portfolio" layout={AsyncBecomeWorkerForm} component={AsyncBecomeWorkerPortfolioForm} />
            <PrivateRoute exact path="/become-worker/category" layout={AsyncBecomeWorkerForm} component={AsyncBecomeWorkerCategoryForm} />

            <PrivateRoute exact path="/worker/account-setting" layout={AsyncWorkerSettingForm} />
            <PrivateRoute exact path="/worker/published-jobs" layout={AsyncWorkerJobListForm} />
            <PrivateRoute exact path="/worker/ongoing-jobs" layout={AsyncWorkerMyJobsForm} />
            <PrivateRoute exact path="/worker/update-profile" layout={AsyncWorkerUpdateProfileForm} />
            <PrivateRoute exact path="/worker/job-detail" layout={AsyncWorkerJobDetailForm} />

            <PrivateRoute exact path="/user/setting" layout={AsyncUserSettingForm} />
            <PrivateRoute exact path="/user/job-list" layout={AsyncUserJobListForm} />
            <PrivateRoute exact path="/user/job-detail" layout={AsyncJobDetailForm} />
            <PrivateRoute exact path="/user/job-post" layout={AsyncUserPostJobForm} />
            <PrivateRoute exact path="/user/ongoing-work" layout={AsyncOnGoingWorkForm} />

            <PrivateRoute exact path="/admin/dashboard" layout={AsyncAdminDashboardForm} />
            <PrivateRoute exact path="/admin/todays-request" layout={AsyncAdminTodaysRequestForm} />
            <PrivateRoute exact path="/admin/users" layout={AsyncAdminUsersForm} />
            <PrivateRoute exact path="/admin/workers" layout={AsyncAdminWorkersForm} />
            <PrivateRoute exact path="/admin/category" layout={AsyncAdminCategoriesForm} />
            <PrivateRoute exact path="/admin/business-type" layout={AsyncAdminBusinessTypeForm} />
            <PrivateRoute exact path="/admin/cities" layout={AsyncAdminCitiesForm} />
            <PrivateRoute exact path="/admin/blog" layout={AsyncAdminBlogForm} />
            <PrivateRoute exact path="/admin/setting" layout={AsyncAdminSettingForm} />

            <PrivateRoute exact path="/admin/user-detail" layout={AsyncAdminUsersDetailForm} />

            <Route component={NotFound} />
        </Switch>
    </Fragment>
);

export default Router;

