import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from "../utils/jwtUtil";

const PrivateRoute = ({component: Component, layout: Layout, ...rest}) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Layout>
                    {Component ?
                        (
                            <Component {...props} />
                        ) :
                        (
                            <></>
                        )
                    }
                </Layout>
            ): (
                <Redirect
                    to={{
                        pathname: '/',
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);

export default PrivateRoute;