import {
    WORKER_EDIT,
    WORKER_MOVE_STEP,
    WORKER_PORTFOLIO_SUCCESS,
    WORKER_PORTFOLIO_FAILURE
} from "../constants/actionType";

let initialState = {
    step: '',
    item: null,
    profileStatus: ''
}

const workerReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case WORKER_MOVE_STEP:
            return Object.assign({}, state, {
                step: action.data
            })

        case WORKER_EDIT:
            return Object.assign({}, state, {
                item: {...state.item, ...action.data}
            })

        case WORKER_PORTFOLIO_SUCCESS:
            return Object.assign({}, state, {
                profileStatus: 'success'
            })

        case WORKER_PORTFOLIO_FAILURE:
            return Object.assign({}, state, {
                profileStatus: 'failed'
            })

        default:
            return state
    }
}

export default workerReducer;