export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'

export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS'
export const USERS_FETCH_FAILURE = 'USERS_FETCH_FAILURE'
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS'
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE'
export const USER_EDIT = 'USER_EDIT'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED'
export const USER_UPDATE_END = 'USER_UPDATE_END'
export const USER_NOTIFICATION_SUCCESS = 'USER_NOTIFICATION_SUCCESS'
export const USER_NOTIFICATION_FAILURE = 'USER_NOTIFICATION_FAILURE'
export const USER_NOTIFICATION_END = 'USER_NOTIFICATION_END'
export const USER_TERMINATE_SUCCESS = 'USER_TERMINATE_SUCCESS'
export const USER_TERMINATE_FAILURE = 'USER_TERMINATE_FAILURE'
export const USER_TERMINATE_END = 'USER_TERMINATE_END'
export const USER_REVIEW_FETCH_SUCCESS = 'USER_REVIEW_FETCH_SUCCESS'
export const USER_REVIEW_FETCH_FAILURE = 'USER_REVIEW_FETCH_FAILURE'
export const USER_IDENTITY_SUCCESS = 'USER_IDENTITY_SUCCESS'
export const USER_IDENTITY_FAILURE = 'USER_IDENTITY_FAILURE'
export const USER_CREDIT_SUCCESS = 'USER_CREDIT_SUCCESS'
export const USER_CREDIT_FAILURE = 'USER_CREDIT_FAILURE'
export const USER_IDENTITY_CHECK_SUCCESS = 'USER_IDENTITY_CHECK_SUCCESS'
export const USER_IDENTITY_CHECK_FAILURE = 'USER_IDENTITY_CHECK_FAILURE'
export const USER_CREDIT_CHECK_SUCCESS = 'USER_CREDIT_CHECK_SUCCESS'
export const USER_CREDIT_CHECK_FAILURE = 'USER_CREDIT_CHECK_FAILURE'
export const WORKERS_FETCH_SUCCESS = 'WORKERS_FETCH_SUCCESS'
export const WORKERS_FETCH_FAILURE = 'WORKERS_FETCH_FAILURE'

export const WORKER_MOVE_STEP = 'WORKER_MOVE_STEP'
export const WORKER_EDIT = 'WORKER_EDIT'
export const WORKER_PORTFOLIO_SUCCESS = 'WORKER_PORTFOLIO_SUCCESS'
export const WORKER_PORTFOLIO_FAILURE = 'WORKER_PORTFOLIO_FAILURE'

export const CITY_SUCCESS = 'CITY_SUCCESS'
export const CITY_FAILURE = 'CITY_FAILURE'
export const CITY_ADD_SUCCESS = 'CITY_ADD_SUCCESS'
export const CITY_ADD_FAILURE = 'CITY_ADD_FAILURE '

export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS'
export const CATEGORY_FAILURE = 'CATEGORY_FAILURE'
export const CATEGORY_TOP_SUCCESS = 'CATEGORY_TOP_SUCCESS'
export const CATEGORY_TOP_FAILURE = 'CATEGORY_TOP_FAILURE'
export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS'
export const CATEGORY_ADD_FAILURE = 'CATEGORY_ADD_FAILURE'

export const BUSINESS_SUCCESS = 'BUSINESS_SUCCESS'
export const BUSINESS_FAILURE = 'BUSINESS_FAILURE'
export const BUSINESS_ADD_SUCCESS = 'BUSINESS_ADD_SUCCESS'
export const BUSINESS_ADD_FAILURE = 'BUSINESS_ADD_FAILURE'

export const JOBS_FETCH_SUCCESS = 'JOBS_FETCH_SUCCESS'
export const JOBS_FETCH_FAILURE = 'JOBS_FETCH_FAILURE'
export const JOBS_COMPLETED_FETCH_SUCCESS = 'JOBS_COMPLETED_FETCH_SUCCESS'
export const JOBS_COMPLETED_FETCH_FAILURE = 'JOBS_COMPLETED_FETCH_FAILURE'
export const JOBS_ONGOING_FETCH_SUCCESS = 'JOBS_ONGOING_FETCH_SUCCESS'
export const JOBS_ONGOING_FETCH_FAILURE = 'JOBS_ONGOING_FETCH_FAILURE'
export const JOB_FETCH_SUCCESS = 'JOB_FETCH_SUCCESS'
export const JOB_FETCH_FAILURE = 'JOB_FETCH_FAILURE'
export const JOB_POST_SUCCESS = 'JOB_POST_SUCCESS'
export const JOB_POST_FAILURE = 'JOB_POST_FAILURE'
export const JOB_POST_END = 'JOB_POST_END'
export const JOB_EDIT = 'JOB_EDIT'
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS'
export const JOB_UPDATE_FAILURE = 'JOB_UPDATE_FAILURE'
export const JOB_QUOTES_FETCH_SUCCESS = 'JOB_QUOTES_FETCH_SUCCESS'
export const JOB_QUOTES_FETCH_FAILURE = 'JOB_QUOTES_FETCH_FAILURE'
export const JOB_QUOTE_EDIT = 'JOB_QUOTE_EDIT'
export const JOB_QUOTE_SUCCESS = 'JOB_QUOTE_SUCCESS'
export const JOB_QUOTE_FAILURE = 'JOB_QUOTE_FAILURE'
export const JOB_QUOTE_UPDATE_SUCCESS = 'JOB_QUOTE_UPDATE_SUCCESS'
export const JOB_QUOTE_UPDATE_FAILURE = 'JOB_QUOTE_UPDATE_FAILURE'
export const JOB_ACCEPT_SUCCESS = 'JOB_ACCEPT_SUCCESS'
export const JOB_ACCEPT_FAILURE = 'JOB_ACCEPT_FAILURE'
export const JOB_DECLINE_SUCCESS = 'JOB_DECLINE_SUCCESS'
export const JOB_DECLINE_FAILURE = 'JOB_DECLINE_FAILURE'
export const JOB_FINISH_SUCCESS = 'JOB_FINISH_SUCCESS'
export const JOB_FINISH_FAILURE = 'JOB_FINISH_FAILURE'