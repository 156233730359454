import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {reducer as formReducer} from 'redux-form';

import authReducer from "./authReducer";
import userReducer from "./userReducer";
import workerReducer from "./workerReducer";
import cityReducer from "./cityReducer";
import categoryReducer from "./categoryReducer";
import businessReducer from "./businessReducer";
import jobReducer from "./jobReducer";

const appReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        auth: authReducer,
        user: userReducer,
        worker: workerReducer,
        city: cityReducer,
        category: categoryReducer,
        business: businessReducer,
        job: jobReducer
    });

const rootReducer = (state, action) => {
    if (action === 'LOG_OUT_SUCCESS') {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;