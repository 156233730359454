import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {getUserType, isAuthenticated} from "../utils/jwtUtil";

const RestrictRoute = ({component: Component, layout: Layout, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                getUserType() === 'worker' ? (
                    <Redirect
                        to={{
                            pathname: '/worker/update-profile',
                            state: {from: props.location}
                        }}
                    />
                ) :
                getUserType() === 'user' ? (
                    <Redirect
                        to={{
                            pathname: '/user/setting',
                            state: {from: props.location}
                        }}
                    />
                ) :
                (
                    <Redirect
                        to={{
                            pathname: '/admin/dashboard',
                            state: {from: props.location}
                        }}
                    />
                )
            ) : (
                <Layout>
                    {Component ?
                        (
                            <Component {...props} />
                        ) :
                        (
                            <></>
                        )
                    }
                </Layout>
            )
        }
    />
);

export default RestrictRoute;
