import React from "react";
import MainRouter from '../routers/router';

function App() {
    return (
        <>
            <MainRouter/>
        </>
    );
}

export default App;
